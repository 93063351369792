'use client';
import { HOME_PAGE, LOGIN_PAGE, SIGNUP_PAGE } from '@/constants/routes.const';
import useService from '@/hooks/useService';
import { AndroidService } from '@/service/Android';
import { AuthService } from '@/service/AuthService';
import { StorageService } from '@/service/StorageService';
import { UserService } from '@/service/User';
import { loadScript, toast } from '@/utils';
import Button from '@/components/ui/Button';
import { useRouter, useSearchParams } from 'next/navigation';
import GoogleIcon from 'public/assets/icons/google.svg';
import LoginIll from 'public/assets/images/login-ill.svg';
import Logo from 'public/assets/logo/logo.svg';
import { useEffect, useState } from 'react';
import { User } from '@/model/Users';
import { LANGUAGES_CONTENT } from '@/constants/language';
import Dialog from '@/components/ui/Dialog';
import Input from '@/components/ui/Input';

export const GOOGLE_RECAPTCHA_BASE_URL =
  'https://www.google.com/recaptcha/enterprise.js?render=';
const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY;

export default function Page() {
  const router = useRouter();
  const params = useSearchParams();
  const redirectionReason = params.get('redirection_reason');
  const {
    call: getCountyCode,
    data: countryCode,
    isLoaded,
  } = useService(UserService.getCountyCode);

  useEffect(() => {
    getCountyCode();
  }, []);

  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()]['login'];

  useEffect(() => {
    if (redirectionReason === 'banned') {
      dispatchEvent(new Event('banned'));
      router.replace(LOGIN_PAGE);
    }
  }, [redirectionReason]);

  return (
    <div className="p-4">
      {!isLoaded ? ( // Conditional rendering for loader
        <div className="flex items-center justify-center h-[90vh]">
          <Logo className="text-[80px] animate-pulse" />{' '}
          {/* Loader with opacity */}
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center my-4">
            <Logo className="text-[80px]" />
          </div>
          <p className="mb-4 text-2xl font-semibold text-center">
            {CONTENT.title}
          </p>

          <div className="">
            <LoginIll className="w-full" />
          </div>
          <div className="flex flex-col items-center justify-center w-full h-60">
            <GoogleLoginBtn countryCode={countryCode} />
            {countryCode === 'IN' && <MobileLogin />}
          </div>
        </>
      )}
    </div>
  );
}

function GoogleLoginBtn({ countryCode }) {
  const router = useRouter();
  const { call: handleGooglelogin } = useService(
    AuthService.handleGooglelogin,
    [],
    null,
    {
      errorMsg: true,
    }
  );

  const [isLoading, setIsLoading] = useState(false);

  async function loginSuccess(data) {
    try {
      const res = await handleGooglelogin(data.id_token);

      if (res.new_user) {
        const path = await getSignupPayload(data);
        router.push(SIGNUP_PAGE + path);
        return;
      }
      User.setCredentials({
        access_token: res.tokens.access_token,
        refresh_token: res.tokens.refresh_token,
        wid: res.wid,
        countryCode,
        userId: res.user?.user_id,
      });
      router.replace(HOME_PAGE);
    } catch (err) {
      AuthService.logout();
      setIsLoading(false);
    }
  }

  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()]['login'];
  const TOAST_MSG = LANGUAGES_CONTENT[StorageService.getLanguage()]['toast'];

  return (
    <div className="flex items-center justify-center w-full h-14 mb-2">
      <Button
        size="4"
        variant="solid"
        className="w-full h-full text-lg"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);
          AndroidService.signInGoogle()
            .then(({ success, data }) => {
              const parsedData = JSON.parse(data);
              if (!success) {
                toast(TOAST_MSG.loginfailed);
                return;
              }
              loginSuccess(parsedData);
            })
            .catch(err => {
              setIsLoading(false);
            });
        }}
      >
        <>
          <GoogleIcon className="text-2xl" /> {CONTENT.cta}
        </>
      </Button>
    </div>
  );
}

function MobileLogin() {
  const [isOpen, setIsOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState();
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const router = useRouter();

  const handleOpenDialog = () => setIsOpen(true);
  const handleCloseDialog = () => {
    if (isLoading) {
      return;
    }
    setIsOpen(false);
    setIsOtpSent(false);
    setPhoneNumber('');
    setOtp();
    setRetryCount(0);
    setCanResend(false);
  };

  const handleGetOtp = async () => {
    if (!phoneNumber || phoneNumber.length !== 10) {
      // Validation for mobile number
      toast('Please enter a valid 10-digit phone number');
      return;
    }
    setIsLoading(true);
    try {
      const recaptchaToken = await generateRecaptchaToken();
      await AuthService.sendMobileOtp(phoneNumber, recaptchaToken, false);
      setOtp();
      setIsOtpSent(true);

      if (retryCount < 2) {
        setRetryCount(v => v + 1); // Increment retry count
        if (retryCount === 1) {
          // If this is the last allowed attempt
          setCanResend(false); // Hide resend option immediately
        } else {
          startTimer(); // Start timer for the first retry
        }
      } else {
        setCanResend(false); // Hide resend option immediately after the last attempt
      }
      toast('OTP sent successfully');
    } catch (error) {
      let errMsg =
        error?.response?.data?.error || 'Failed to send OTP. Please try again.';
      toast(errMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = () => {
    if (retryCount < 2) {
      // Allow resend if less than 2 attempts
      handleGetOtp();
    }
  };

  useEffect(() => {
    initRecaptcha();
  }, []);

  async function initRecaptcha() {
    const isLoaded = await loadScript(
      GOOGLE_RECAPTCHA_BASE_URL + RECAPTCHA_KEY
    );
    if (isLoaded) {
      window.grecaptcha.enterprise.ready(() => {});
    } else {
      console.log('Recaptcha failed to load');
    }
  }

  async function generateRecaptchaToken() {
    let token;
    if (window.grecaptcha) {
      token = await window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, {
        action: 'otp',
      });
    } else {
      initRecaptcha();
    }
    return token;
  }

  const startTimer = () => {
    setCanResend(false);
    setTimer(30);
    const interval = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(interval);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleLogin = async () => {
    if (!otp || otp.length !== 6) {
      // Validation for OTP
      toast('Please enter a valid 6-digit OTP');
      return;
    }
    setIsLoading(true);
    try {
      const res = await AuthService.handleMobileLogin(
        phoneNumber,
        otp,
        AndroidService.getDeviceIdentifiers().ga_id
      );
      if (res.new_user) {
        const path = await getSignupPayloadForPhoneLogin({ phoneNumber, otp });
        router.push(SIGNUP_PAGE + path);
        return;
      }

      const countryCode = await UserService.getCountyCode();
      User.setCredentials({
        access_token: res.tokens.access_token,
        refresh_token: res.tokens.refresh_token,
        wid: res.wid,
        countryCode,
        userId: res.user?.user_id,
      });
      router.replace(HOME_PAGE);
      handleCloseDialog();
    } catch (error) {
      let errMsg =
        error?.response?.data?.error || 'Login failed. Please try again.';
      toast(errMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const CONTENT =
    LANGUAGES_CONTENT[StorageService.getLanguage()]['login'].phoneLogin; // Get localized content

  return (
    <>
      <div className="flex items-center justify-center w-full h-14 mb-2">
        <Button
          onClick={handleOpenDialog}
          isLoading={isLoading && !isOpen}
          solid
          size="4"
          className="w-full h-full text-lg bg-purple-600"
        >
          {CONTENT.title} {/* Use localized title */}
        </Button>
      </div>
      <Dialog isOpen={isOpen} onOpenChange={handleCloseDialog}>
        <div className="flex flex-col items-center p-4">
          {!isOtpSent ? (
            <>
              <h2 className="text-xl font-semibold mb-4">
                {CONTENT.enterPhone}
              </h2>{' '}
              {/* Localized text */}
              <label className="mb-2 text-sm text-gray-600">
                {CONTENT.phoneDescription} {/* Localized description */}
              </label>
              <Input
                type="tel"
                placeholder=""
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                className="mb-4"
                disabled={isLoading}
              />
              <Button
                onClick={handleGetOtp}
                disabled={isLoading}
                isLoading={isLoading}
                className="w-full"
                size="4"
                variant="solid"
              >
                {CONTENT.getOtp} {/* Localized button text */}
              </Button>
            </>
          ) : (
            <>
              <h2 className="text-xl font-semibold mb-4">{CONTENT.enterOtp}</h2>{' '}
              {/* Localized text */}
              <label className="mb-2 text-sm text-gray-600">
                {CONTENT.otpDescription} {/* Localized description */}
              </label>
              <OtpInput value={otp} onChange={e => setOtp(e.target.value)} />
              <Button
                onClick={handleLogin}
                disabled={isLoading}
                isLoading={isLoading}
                className="w-full my-2 "
                size="4"
                variant="solid"
              >
                {CONTENT.submit} {/* Localized button text */}
              </Button>
              {retryCount > 1 ? null : !canResend ? (
                <p className="text-sm text-gray-600">
                  {CONTENT.retryOtp.replace('{timer}', timer)}{' '}
                  {/* Localized text with timer */}
                </p>
              ) : (
                <button
                  disabled={isLoading}
                  onClick={handleResendOtp}
                  className="text-blue-500 cursor-pointer hover:underline"
                >
                  {CONTENT.resendOtp} {/* Localized text */}
                </button>
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}

function OtpInput({ value, onChange }) {
  return (
    <input
      type="number"
      value={value} // Bind the input value to the state
      onChange={onChange}
      className="w-full h-12 text-center border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 outline-none text-lg" // Ensure proper sizing
    />
  );
}

async function getSignupPayload(data) {
  let utmSource = await AndroidService.getInstallReferrerData();
  console.log('utmSource', utmSource);
  return jsonToQueryString({
    code: data.id_token,
    utmSource,
    fullName: data.display_name,
  });
}
async function getSignupPayloadForPhoneLogin({ phoneNumber, otp }) {
  let utmSource = await AndroidService.getInstallReferrerData();
  console.log('utmSource mobile', utmSource);
  return jsonToQueryString({
    utmSource,
    isMobileLogin: true,
    phoneNumber,
    otp,
  });
}

function jsonToQueryString(json) {
  return (
    '?' +
    Object.keys(json)
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}
